<template>
    <div class="flex justify-center items-center h-full">
        <div class="relative flex flex-col bg-white p-10 sm:p-12 shadow-md rounded w-auto  sm:w-104">
            <img src="@/assets/images/logo_color.png" class="self-center mb-4" width="200px" alt="">

            <el-form ref="formData" :model="formData" label-position="top" :rules="rules" class="flex flex-col border-t border-gray-300 pt-6 mb-4">
                <el-form-item :label="$t('password_reset.email')" prop="email">
                    <el-input v-model="formData.email" />
                </el-form-item>
                <el-form-item :label="$t('password_reset.password')" prop="password">
                    <el-input v-model="formData.password" type="password" show-password auto-complete="new-password" />
                </el-form-item>
                <el-form-item :label="$t('password_reset.confirm_password')" prop="password_confirm">
                    <el-input v-model="formData.password_confirm" type="password" show-password />
                </el-form-item>
            </el-form>

            <button
                v-wait:hidden="'logging'"
                class="bg-indigo-500 text-white self-end py-2 px-6 rounded shadow hover:bg-indigo-400 transition outline-none focus:outline-none active:bg-blue-700"
                @click.prevent="resetPassword"
            >
                {{ $t('reset_password_page.reset_password') }}
            </button>
            <button
                v-wait:visible="'logging'"
                disabled
                class="relative bg-indigo-500 text-white self-end py-2 px-6 rounded shadow hover:bg-indigo-400 transition outline-none focus:outline-none active:bg-blue-700"
            >
                <pulse-loader :loading="true" color="#b5c7ff" size="10px" />
            </button>
            <p
                class="relative sm:absolute bottom-0 mt-5 sm:mt-0 sm:mb-3 self-center text-sm text-red-500 transition"
                :class="showErrorMessage || 'hidden'"
            >
                {{ $t('reset_password_page.incorrect_email_please_try_again') }}
            </p>
        </div>
    </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import queryString from 'query-string';

export default {
    components: {
        PulseLoader,
    },

    data() {
        return {
            token:    null,
            formData: {
                email:            '',
                password:         '',
                password_confirm: '',
            },
            rules: {
                email: [{
                    required: true, type: 'email', message: this.$t('common.required'), trigger: 'blur',
                }],
                password: [
                    { required: true, message: this.$t('common.required'), trigger: 'change' },
                    { min: 8, message: this.$t('password_reset.validation_min_8_characters') },
                ],
                password_confirm: [
                    { required: true, message: this.$t('common.required'), trigger: 'change' },
                    { min: 8, message: this.$t('password_reset.validation_min_8_characters') },
                ],
            },
            showErrorMessage: false,
        };
    },

    created() {
        const { token } = queryString.parse(window.location.search, { sort: false });

        if (!token) {
            this.$router.push('/login');
            return;
        }

        this.token = token;
    },

    methods: {
        async resetPassword() {
            await new Promise(resolve => this.$refs.formData.validate(valid => valid && resolve()));
            this.sendRequest();
        },

        async sendRequest() {
            this.$wait.start('logging');

            try {
                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/password_reset_complete`, {
                    token:                 this.token,
                    email:                 this.formData.email,
                    password:              this.formData.password,
                    password_confirmation: this.formData.password_confirm,
                });
                this.responseSuccess();
            } catch ($error) {
                this.responseError();
            }
        },

        firstValidation() {
            this.showErrorMessage = false;

            if (!this.email) {
                this.showErrorMessage = true;
                return false;
            }

            return true;
        },

        responseSuccess() {
            this.showErrorMessage = false;
            this.formData.email = '';
            this.formData.password = '';
            this.formData.password_confirm = '';
            this.$wait.end('logging');
            this.$router.push('/login');
        },

        responseError() {
            this.showErrorMessage = true;
            this.$wait.end('logging');
        },
    },
};
</script>
